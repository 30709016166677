import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';

import types from './types';
import enums from '~/utils/enums';

//= ============== SELECTOR ===============//
const loadStatus = (state) => state.getIn(['resume', 'loadStatus']);
const getResumeList = (state) => state.getIn(['resume', 'listResumes']);
const paginate = (state) => state.getIn(['resume', 'paginate']);

export const selectors = {
    loadStatus,
    getResumeList,
    paginate
};

//= ============== REDUCER ===============//
const initState = fromJS({
    loadStatus: enums.STATUS.LOADING,
    listResumes: [],
    paginate: {
        currentPage: 1,
        perPage: 1,
        total: 1
    },
    resume: {}
});

const loadSuccess = (state) => state.set('loadStatus', enums.STATUS.SUCCESS);
const loadFail = (state) => state.set('loadStatus', enums.STATUS.FAIL);
const loading = (state) => state.set('loadStatus', enums.STATUS.LOADING);
const storePaginate = (state, action) => state.set('paginate', fromJS(action.payload));
const handleListResumeSuccess = (state, action) => state.set('listResumes', fromJS(action.payload));
const handleDeleteResume = (state, action) => {
    const newState = state.get('listResumes').filter((item) => item.get('id') !== action.payload);

    return state.setIn(['listResumes'], newState);
};
const handleChangeStatus = (state, action) => {
    const {id} = action.payload;
    const dispatchStatus = 1;

    const newState = state.setIn(['listResumes', state.get('listResumes').findIndex((item) => item.get('id') === id), 'status'], fromJS(dispatchStatus));

    window.open(`/api/export-pdf/${id}`, '_blank');
    return newState;
};

const reducer = handleActions({
    [types.FETCH_RESUMES]: loading,
    [types.LOAD_SUCCESS]: loadSuccess,
    [types.LOAD_FAIL]: loadFail,
    [types.STORE_PAGINATE]: storePaginate,
    [types.FETCH_RESUMES_SUCCESS]: handleListResumeSuccess,
    [types.DELETE_RESUMES_SUCCESS]: handleDeleteResume,
    [types.CHANGE_STATUS]: handleChangeStatus
}, initState);

export default reducer;
