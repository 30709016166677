import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Immutable from 'immutable';
import LayoutHomePageComponent from './LayoutHomePages';
import LayoutAdminComponent from './LayoutAdmins';
import {getAuthUser} from '~/state/modules/auth';

const Layout = ({children, authUser}) => {
    const url = window.location.pathname.split('/');
    if (url[1] === 'admin') {
        return authUser ? <LayoutAdminComponent childrenProps={children} /> : window.location = '/login';
    }
    return (
        <LayoutHomePageComponent childrenProps={children} />
    );
};

Layout.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

Layout.defaultProps = {
    authUser: {}
};

Layout.propTypes = {
    authUser: PropTypes.instanceOf(Immutable.Map)
};

const mapStateToProps = (state) => ({
    authUser: getAuthUser(state)
});

export default connect(mapStateToProps, null)(Layout);
