import React from 'react';
import {Link, useLocation} from 'react-router-dom';
import {Layout, Menu} from 'antd';
import PropTypes from 'prop-types';
import {
    FileOutlined,
    TeamOutlined,
    ProjectOutlined,
    CrownOutlined,
    DeploymentUnitOutlined,
    WalletOutlined,
    DribbbleOutlined
} from '@ant-design/icons';
import sunLogo from '~/assets/images/head_logo.svg';

const {Sider} = Layout;
const {SubMenu} = Menu;

const navLinks = [
    {
        key: 'team-ai',
        label: 'AI Research',
        icon: <TeamOutlined />,
        submenu: [
            {key: 'team-ai/big-products', label: 'Big Products/Poc', url: '/admin/team-ai/big-products', icon: <ProjectOutlined />},
            {key: 'team-ai/activities', label: 'Activities', url: '/admin/team-ai/activities', icon: <DribbbleOutlined />}
        ]
    },
    {
        key: 'blockchain',
        label: 'Blockchain Research',
        url: '/admin/blockchain-team',
        icon: <TeamOutlined />,
        submenu: [
            {key: 'blockchain/certificates', label: 'Certificates', url: '/admin/blockchain/certificates', icon: <DeploymentUnitOutlined />},
            {key: 'blockchain/product', label: 'Product', url: '/admin/blockchain/product', icon: <ProjectOutlined />},
            {key: 'blockchain/achievement', label: 'Activities', url: '/admin/blockchain/achievement', icon: <CrownOutlined />}
        ]
    },
    {
        key: 'cyber-security',
        label: 'Cyber Security Research',
        icon: <TeamOutlined />,
        submenu: [
            {key: 'cyber-security/certificates', label: 'Certificates', url: '/admin/cyber-security/certificates', icon: <DeploymentUnitOutlined />},
            {key: 'cyber-security/achievement', label: 'Achievement', url: '/admin/cyber-security/achievement', icon: <CrownOutlined />},
            {key: 'cyber-security/hacktivity', label: 'Hacktivity', url: '/admin/cyber-security/hacktivity', icon: <CrownOutlined />}
        ]
    },
    {
        key: 'product-development',
        label: 'Products Development',
        icon: <TeamOutlined />,
        submenu: [
            {key: 'product-development/product', label: 'Product', url: '/admin/product-development/product', icon: <DeploymentUnitOutlined />}
        ]
    },
    {key: 'publications', label: 'Publications', url: '/admin/publications', icon: <WalletOutlined />},
    {key: 'resumes', label: 'Resumes', url: '/admin/resumes', icon: <FileOutlined />}
];

const SiderAdmin = ({collapsed, onCollapse}) => {
    const location = useLocation();
    const arrayLabel = ['AI Research', 'Blockchain Research', 'Cyber Security Research', 'Products Development'];
    const navLinkUrl = navLinks.map((item) => {
        if (arrayLabel.includes(item.label)) {
            return (
                <SubMenu key={item.key} icon={item.icon} title={item.label}>
                    {item.submenu.map((subItem) => (
                        <Menu.Item key={subItem.key}>
                            <Link to={subItem.url}>
                                {subItem.icon}
                                <span>{subItem.label}</span>
                            </Link>
                        </Menu.Item>
                    ))}
                </SubMenu>
            );
        }

        return (
            <Menu.Item key={item.key}>
                <Link to={item.url}>
                    {item.icon}
                    <span>{item.label}</span>
                </Link>
            </Menu.Item>
        );
    });

    const setSelectKey = () => {
        const match = location.pathname.match(/\/admin\/([\w|-]+\/?(?!create|edit)[\w|-]+)/);
        if (match) {
            return match[1];
        }
    };

    return (
        <Sider collapsible collapsed={collapsed} onCollapse={onCollapse} width='270'>
            <div className='logo h-8 my-4 mx-2 flex justify-center items-center'>
                <img src={sunLogo} alt='SUN' className='h-6' />
                <span className='text-3xl font-black text-white pl-2'>R&D</span>
            </div>
            <Menu theme='dark' defaultSelectedKeys={[`${setSelectKey()}`]} defaultOpenKeys={[`${location.pathname.split('/')[2]}`]} mode='inline'>
                {navLinkUrl}
            </Menu>
        </Sider>
    );
};

SiderAdmin.propTypes = {
    collapsed: PropTypes.bool.isRequired,
    onCollapse: PropTypes.func.isRequired
};

export default SiderAdmin;
