import React, {useState} from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';

import './style.scss';

import HeaderAdmin from './Header';
import FooterAdmin from './Footer';
import SiderAdmin from './Sider';

const {Content} = Layout;

const LayoutAdminComponent = ({childrenProps}) => {
    const [collapsed, setCollapsed] = useState(false);

    function onCollapse() {
        setCollapsed(!collapsed);
    }

    return (
        <Layout style={{minHeight: '100vh'}}>
            <SiderAdmin collapsed={collapsed} onCollapse={onCollapse} />
            <Layout className='site-layout'>
                <HeaderAdmin />
                <Content style={{margin: '16px 16px'}}>
                    {childrenProps}
                </Content>
                <FooterAdmin />
            </Layout>
        </Layout>
    );
};

LayoutAdminComponent.propTypes = {
    childrenProps: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default LayoutAdminComponent;
