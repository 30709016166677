import axios from '~/utils/axios';

// Route Admin
const getListPostApi = (param) => axios.get('/api/admin/post', {params: param});
const changeStatus = (id, param) => axios.post(`/api/admin/change-status/${id}`, param);
const apiStore = (data) => axios.post('/api/admin/post', data);
const editPostApi = (id) => axios.get(`/api/admin/post/${id}/edit`);
const updatePostApi = (id, param) => axios.put(`/api/admin/post/${id}`, param);
const deletePost = (id) => axios.delete(`/api/admin/post/${id}`);
const changePinApi = (id, param) => axios.post(`/api/admin/change-pin/${id}`, param);

// Route Homepage
const getListPostByCategoryIdApi = (param) => axios.get('/api/homepage/getListPost', {params: param});
const getListAchievementApi = (param) => axios.get('/api/homepage/getListAchievement', {params: param});
const getListHacktivityApi = (param) => axios.get('/api/homepage/getListHacktivity', {params: param});
const getListPublication = (param) => axios.get('/api/homepage/getListPublication', {params: param});
const getListActivitiesApi = (param) => axios.get('/api/homepage/getListActivities', {params: param});
const deleteTagApi = (param) => axios.delete('/api/admin/delete-tag', {params: param});

export default {
    getListPostApi,
    changeStatus,
    apiStore,
    editPostApi,
    updatePostApi,
    deletePost,
    getListPostByCategoryIdApi,
    getListAchievementApi,
    getListHacktivityApi,
    getListActivitiesApi,
    getListPublication,
    deleteTagApi,
    changePinApi
};
