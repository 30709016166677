import {put, call, takeLatest} from 'redux-saga/effects';
import {createAction} from 'redux-actions';
import {message} from 'antd';
import api from '~/api/CurriculumVitae';
import types from './types';
import handleError from '~/utils/ErrorHandler';

//= ============== ACTIONS ===============//
const fetchResumesSuccess = createAction(types.FETCH_RESUMES_SUCCESS);
const storePaginate = createAction(types.STORE_PAGINATE);
const loadSuccess = createAction(types.LOAD_SUCCESS);
const loadFail = createAction(types.LOAD_FAIL);
const getFetchResumes = createAction(types.FETCH_RESUMES);
const deleteResumes = createAction(types.DELETE_RESUMES);
const deleteCVSuccess = createAction(types.DELETE_RESUMES_SUCCESS);
const changeStatus = createAction(types.CHANGE_STATUS);

export const actions = {
    getFetchResumes,
    deleteResumes,
    changeStatus
};

//= =============== SAGAS ===============//
export function* sagas() {
    yield takeLatest(types.FETCH_RESUMES, getResumeList);
    yield takeLatest(types.DELETE_RESUMES, deleteResumeList);
}

function* getResumeList({payload}) {
    try {
        const response = yield call(api.getListCVApi, payload);
        const {data, meta} = response.data;
        yield put(fetchResumesSuccess(data));
        const dataPaginate = {
            currentPage: meta.current_page,
            perPage: meta.per_page,
            total: meta.total
        };
        yield put(storePaginate(dataPaginate));
        yield put(loadSuccess());
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}

function* deleteResumeList({payload}) {
    try {
        const response = yield call(api.deleteCVApi, payload);
        if (response.status === 200) {
            yield put(deleteCVSuccess(payload));
            message.success('cv deleted successfully!!!');
        } else {
            message.error('Something went wrong');
        }
    } catch (error) {
        handleError(error);
    }
}
