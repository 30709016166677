/* eslint-disable */
import Loadable from '~/utils/Loadable/';

const routeHomePageAdmins = [
    {
        exact: true,
        path: '/admin',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin')),
        name: 'home'
    },
    {
        exact: true,
        path: '/admin/product-development/product',
        component: Loadable(() => import('~/views/pages/Admin/ProductDevelopment/Post')),
        name: 'admin-post'
    },
    {
        exact: true,
        path: '/admin/product-development/product/Create',
        component: Loadable(() => import('~/views/pages/Admin/ProductDevelopment/Post/Create')),
        name: 'admin-create-post'
    },
    {
        exact: true,
        path: '/admin/product-development/product/Edit/:id',
        component: Loadable(() => import('~/views/pages/Admin/ProductDevelopment/Post/Edit')),
        name: 'admin-edit-post'
    },
    {
        exact: true,
        path: '/admin/blockchain/product/create',
        component: Loadable(() => import('~/views/pages/Admin/Blockchain/Gamming/create')),
        name: 'admin-create-gamming'
    },
    {
        exact: true,
        path: '/admin/blockchain/achievement',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Blockchain/Achievement')),
        name: 'blockchain-achievement'
    },
    {
        exact: true,
        path: '/admin/blockchain/certificates',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Blockchain/Certificates')),
        name: 'blockchain-certificates'
    },
    {
        exact: true,
        path: '/admin/blockchain/certificates/create',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Blockchain/Certificates/create')),
        name: 'blockchain-certificates-create'
    },
    {
        exact: true,
        path: '/admin/blockchain/certificates/edit/:id',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Blockchain/Certificates/edit')),
        name: 'blockchain-certificates-edit'
    },
    {
        name: 'admin-achievements',
        exact: true,
        path: '/admin/cyber-security/achievement',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Cyber/Achievements'))
    },
    {
        name: 'admin-achievements-create',
        exact: true,
        path: '/admin/cyber-security/achievement/create',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Cyber/Achievements/create'))
    },
    {
        name: 'admin-achievements-edit',
        exact: true,
        path: '/admin/cyber-security/achievement/edit/:id',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Cyber/Achievements/edit'))
    },
    {
        exact: true,
        path: '/admin/cyber-security/hacktivity',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Cyber/Hacktivity')),
        name: 'admin-hacktivity'
    },
    {
        exact: true,
        path: '/admin/cyber-security/hacktivity/create',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Cyber/Hacktivity/create')),
        name: 'admin-hacktivity-create'
    },
    {
        exact: true,
        path: '/admin/cyber-security/hacktivity/edit/:id',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Cyber/Hacktivity/edit')),
        name: 'admin-hacktivity-edit'
    },
    {
        exact: true,
        path: '/admin/cyber-security/certificates',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Cyber/Certificates')),
        name: 'admin-certificates'
    },
    {
        exact: true,
        path: '/admin/cyber-security/certificates/create',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Cyber/Certificates/create')),
        name: 'admin-certificates-create'
    },
    {
        exact: true,
        path: '/admin/cyber-security/certificates/edit/:id',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Cyber/Certificates/edit')),
        name: 'admin-certificates-edit'
    },
    {
        exact: true,
        path: '/admin/blockchain/product',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Blockchain/Gamming')),
        name: 'blockchain-gamming'
    },
    {
        exact: true,
        path: '/admin/resumes',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Resumes')),
        name: 'resumes'
    },
    {
        exact: true,
        path: '/admin/blockchain/product/edit/:id',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Blockchain/Gamming/edit')),
        name: 'blockchain-gamming-edit'
    },
    {
        exact: true,
        path: '/admin/blockchain/achievement/create',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Blockchain/Achievement/create')),
        name: 'blockchain-achievement-create'
    },
    {
        exact: true,
        path: '/admin/blockchain/achievement/edit/:id',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Blockchain/Achievement/edit')),
        name: 'blockchain-achievement-edit'
    },
    {
        exact: true,
        path: '/admin/team-ai/big-products',
        component: Loadable(() => import('~/views/pages/Admin/TeamAI/BigProducts')),
        name: 'big-products'
    },
    {
        path: '/admin/team-ai/big-products/create',
        component: Loadable(() => import('~/views/pages/Admin/TeamAI/BigProducts/create')),
        name: 'big-products-create'
    },
    {
        path: '/admin/team-ai/big-products/edit/:id',
        component: Loadable(() => import('~/views/pages/Admin/TeamAI/BigProducts/edit')),
        name: 'big-products-edit'
    },
    {
        exact: true,
        path: '/admin/publications',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Publications')),
        name: 'publications'
    },
    {
        exact: true,
        path: '/admin/publications/create',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Publications/create')),
        name: 'publications-create'
    },
    {
        exact: true,
        path: '/admin/publications/edit/:id',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/Publications/edit')),
        name: 'publication-edit'
    },
    {
        exact: true,
        path: '/admin/team-ai/activities',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/TeamAI/Activities')),
        name: 'ai-activities'
    },
    {
        exact: true,
        path: '/admin/team-ai/activities/create',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/TeamAI/Activities/create')),
        name: 'ai-activities-create'
    },
    {
        exact: true,
        path: '/admin/team-ai/activities/edit/:id',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/TeamAI/Activities/edit')),
        name: 'ai-activities-edit'
    },
    {
        exact: true,
        path: '/admin/activities',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/TeamAI/Activities')),
        name: 'ai-activities'
    },
    {
        exact: true,
        path: '/admin/activities/create',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/TeamAI/Activities/create')),
        name: 'ai-activities-create'
    },
    {
        exact: true,
        path: '/admin/activities/edit/:id',
        component: Loadable(() => import(/* webpackChunkName: "js/home" */ '~/views/pages/Admin/TeamAI/Activities/edit')),
        name: 'ai-activities-edit'
    }
];
export default routeHomePageAdmins;
/* eslint-disable */
