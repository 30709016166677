import {fromJS} from 'immutable';
import {handleActions} from 'redux-actions';
import types from './types';
import enums from '~/utils/enums';

//= ============== SELECTOR ===============//
const loadStatus = (state) => state.getIn(['post', 'loadStatus']);
const getPostList = (state) => state.getIn(['post', 'listPosts']);
const showPost = (state) => state.getIn(['post', 'post']);
const paginate = (state) => state.getIn(['post', 'paginate']);
const paginateHacktivity = (state) => state.getIn(['post', 'paginateHacktivity']);
const paginateAchievement = (state) => state.getIn(['post', 'paginateAchievement']);
const paginateActivities = (state) => state.getIn(['post', 'paginateActivities']);
const listPostHomepage = (state) => state.getIn(['post', 'listPostHomepage']);
const listPostAchievement = (state) => state.getIn(['post', 'listAchievement']);
const listPostHacktivity = (state) => state.getIn(['post', 'listHacktivity']);
const listPostActivities = (state) => state.getIn(['post', 'listActivities']);
const listTagsOfPost = (state) => state.getIn(['post', 'postTags']);
const listPublication = (state) => state.getIn(['post', 'listPublication']);
const serverErrors = (state) => state.getIn(['post', 'serverErrors']);

export const selectors = {
    loadStatus,
    getPostList,
    paginate,
    showPost,
    serverErrors,
    listPostHomepage,
    listPostAchievement,
    listPostHacktivity,
    paginateHacktivity,
    paginateAchievement,
    listTagsOfPost,
    paginateActivities,
    listPostActivities,
    listPublication
};

//= ============== REDUCER ===============//
const initState = fromJS({
    loadStatus: enums.STATUS.FAIL,
    listPosts: [],
    paginate: {currentPage: 1, perPage: 1, total: 1},
    paginateHacktivity: {currentPage: 1, perPage: 1, total: 1},
    paginateAchievement: {currentPage: 1, perPage: 1, total: 1},
    paginateActivities: {currentPage: 1, perPage: 1, total: 1},
    post: {},
    serverErrors: fromJS({}),
    lang: 'EN',
    listPostHomepage: {},
    listAchievement: {},
    listHacktivity: {},
    listPublication: {},
    listActivities: [],
    postTags: []
});

const handleStorePost = (state, action) => {
    let newState = state.set('loadStatus', enums.STATUS.SUCCESS).set('serverErrors', fromJS({}));
    const {data} = action.payload;
    newState = newState.updateIn(['listPosts'], (list) => list.unshift(fromJS(data)));
    return newState;
};
const handleUpdatePost = (state, action) => state.setIn(['post'], fromJS(action.payload.data));
const handleSetServerErrors = (state, action) => state.set('serverErrors', fromJS(action.payload));
const loadSuccess = (state) => state.set('loadStatus', enums.STATUS.SUCCESS).set('serverErrors', fromJS({}));
const loadFail = (state) => state.set('loadStatus', enums.STATUS.FAIL);
const loading = (state) => state.set('loadStatus', enums.STATUS.LOADING).set('serverErrors', fromJS({}));
const storePaginate = (state, action) => state.set('paginate', fromJS(action.payload));
const storePaginateHacktivity = (state, action) => state.set('paginateHacktivity', fromJS(action.payload));
const storePaginateActivities = (state, action) => state.set('paginateActivities', fromJS(action.payload));
const storePaginateAchievement = (state, action) => state.set('paginateAchievement', fromJS(action.payload));
const handleListPostSuccess = (state, action) => state.set('listPosts', fromJS(action.payload));
const handleChangeStatus = (state, action) => {
    const {postId, postStatus} = action.payload;

    const newState = state.setIn(['listPosts', state.get('listPosts').findIndex((item) => item.get('id') === postId), 'status'],
        fromJS(postStatus)
    );

    return newState;
};
const handleChangePin = (state, action) => {
    const {postId, pin} = action.payload;
    const newState = state.setIn(['listPosts', state.get('listPosts').findIndex((item) => item.get('id') === postId), 'pin'],
        fromJS(pin)
    );

    return newState;
};
const handleDeletePost = (state, action) => {
    const newState = state.get('listPosts').filter((item) => item.get('id') !== action.payload);

    return state.setIn(['listPosts'], newState);
};

const handleShowEditPost = (state, action) => {
    let newState = state.set('loadStatus', enums.STATUS.LOADING);
    newState = newState.setIn(['post'], fromJS(action.payload));
    newState = newState.setIn(['postTags'], fromJS(action.payload.tags));
    return newState;
};

const clearPost = (state) => {
    const newState = state.setIn(['post'], fromJS({}));
    return newState;
};

const clearListPostHomepage = (state) => {
    let newstate = state.setIn(['listPostHomepage'], fromJS({}));
    newstate = newstate.set('loadStatus', enums.STATUS.LOADING);

    return newstate;
};

const handleListPostByCategorySuccess = (state, action) => state.set('listPostHomepage', fromJS(action.payload));

const handleListAchievementSuccess = (state, action) => state.set('listAchievement', fromJS(action.payload));

const handleListHacktivitySuccess = (state, action) => state.set('listHacktivity', fromJS(action.payload));

const handleListActivitiesSuccess = (state, action) => state.set('listActivities', fromJS(action.payload));

const handleListPublicationSuccess = (state, action) => state.set('listPublication', fromJS(action.payload));

const handleDeleteTagSuccess = (state, action) => {
    const newState = state.get('postTags').filter((item) => item.get('id') !== action.payload.tagToDelete);
    return state.setIn(['postTags'], newState);
};

const handleFetchPublication = (state) => state.set('loadStatus', enums.STATUS.LOADING);

const reducer = handleActions({
    [types.FETCH_POSTS]: loading,
    [types.LOAD_SUCCESS]: loadSuccess,
    [types.LOAD_FAIL]: loadFail,
    [types.STORE_PAGINATE]: storePaginate,
    [types.FETCH_POSTS_SUCCESS]: handleListPostSuccess,
    [types.CHANGE_STATUS]: loadSuccess,
    [types.CHANGE_STATUS_SUCCESS]: handleChangeStatus,
    [types.CHANGE_PIN_SUCCESS]: handleChangePin,
    [types.STORE_POST_SUCCESS]: handleStorePost,
    [types.SHOW_EDIT_POST]: handleShowEditPost,
    [types.UPDATE_POST_SUCCESS]: handleUpdatePost,
    [types.CLEAR_DATA]: clearPost,
    [types.STORE_POST]: loading,
    [types.UPDATE_POST]: loading,
    [types.STORE_POST_FAILED]: handleSetServerErrors,
    [types.DELETE_POST_SUCCESS]: handleDeletePost,
    [types.FETCH_POST_HOMEPAGE_SUCCESS]: handleListPostByCategorySuccess,
    [types.FETCH_POST_HOMEPAGE]: clearListPostHomepage,
    [types.FETCH_ACHIEVEMENT_SUCCESS]: handleListAchievementSuccess,
    [types.FETCH_HACKTIVITY_SUCCESS]: handleListHacktivitySuccess,
    [types.FETCH_ACTIVITIES_SUCCESS]: handleListActivitiesSuccess,
    [types.STORE_PAGINATE_ACTIVITIES]: storePaginateActivities,
    [types.STORE_PAGINATE_ACHIEVEMENT]: storePaginateAchievement,
    [types.STORE_PAGINATE_HACKTIVITY]: storePaginateHacktivity,
    [types.FETCH_PUBLICATION]: handleFetchPublication,
    [types.FETCH_PUBLICATION_SUCCESS]: handleListPublicationSuccess,
    [types.DELETE_TAG_SUCCESS]: handleDeleteTagSuccess
}, initState);

export default reducer;
