import {combineReducers} from 'redux-immutable';

import auth from './modules/auth';
import example from './modules/example';
import post from './modules/post';
import language from './modules/language';
import resume from './modules/CurriculumVitae';
import tag from './modules/tag';

/**
 * Creates the root reducer with the asynchronously loaded ones
 */
export default function rootReducer(asyncReducers) {
    return combineReducers({
        auth,
        example,
        post,
        resume,
        language,
        tag,
        ...asyncReducers
    });
}
