import React from 'react';
import { Link, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import teams from '~/utils/teams';
import Logo from '~/assets/images/logo.png';
import './style.scss';

const Footer = () => {
    const { t } = useTranslation();
    const teamItems = [{
        title: t('AI Research'),
        link: '/team-ai'
    }, {
        title: t('Blockchain Research'),
        link: '/blockchain'
    }, {
        title: t('Cyber Security Research'),
        link: '/cyber-security'
    }, {
        title: t('Product Development'),
        link: '/product'
    }];
    const careersItems = [{
        teamID: teams.TEAM_IDS.AI,
        title: t('AI Research'),
        link: '/careers'
    }, {
        teamID: teams.TEAM_IDS.BLOCKCHAIN,
        title: t('Blockchain Research'),
        link: '/careers'
    }, {
        teamID: teams.TEAM_IDS.CYBER,
        title: t('Cyber Security Research'),
        link: '/careers'
    }, {
        teamID: teams.TEAM_IDS.PRODUCT,
        title: t('Open Source Research'),
        link: '/careers'
    }];
    return (
        <footer className='block bg-red-600 overflow-hidden footer-homepage mt-12 lg:mt-24 px-8'>
            <div className='container m-auto text-white pb-8 lg:pt-24'>
                <div className='logo flex flex-col sm:flex-row sm:items-center pt-3'>
                    <span className='inline-block'><img src={Logo} alt='Logo' /></span>
                    <span className='font-bold text-5xl sm:ml-4 inline-block leading-none'>R&D Lab</span>
                </div>
                <div className='flex items-center justify-between flex-wrap lg:flex-no-wrap'>
                    <div className='w-full block'>&nbsp;</div>
                    <div className='w-full block flex-grow lg:flex'>
                        <div className='text-sm w-full mb-8 lg:mb-0'>
                            <h3 className='footer-menu-title text-2xl text-white mb-4 lg:mb-8'>{t('Teams')}</h3>
                            {teamItems.map((item) => (
                                <Link
                                    key={item.link}
                                    to={item.link}
                                    className='block mt-4'
                                    onClick={() => { window.scrollTo(0, 0); }}
                                >
                                    {item.title}
                                </Link>
                            ))}
                        </div>
                        <div className='text-sm w-full'>
                            <h3 className='footer-menu-title text-2xl text-white mb-4 lg:mb-8'>{t('Careers')}</h3>
                            {careersItems.map((item, index) => (
                                <NavLink
                                    key={index}
                                    to={`${item.link}#${item.teamID}`}
                                    className='block mt-4'
                                >
                                    <div>{item.title}</div>
                                </NavLink>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
