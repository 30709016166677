/* eslint-disable max-lines */
import {put, call, takeLatest, delay} from 'redux-saga/effects';
import {createAction} from 'redux-actions';
import {message} from 'antd';
import api from '~/api/post';
import types from './types';
import handleError from '~/utils/ErrorHandler';

//= ============== ACTIONS ===============//
const fetchPostsSuccess = createAction(types.FETCH_POSTS_SUCCESS);
const storePaginate = createAction(types.STORE_PAGINATE);
const loadSuccess = createAction(types.LOAD_SUCCESS);
const loadFail = createAction(types.LOAD_FAIL);
const changeStatus = createAction(types.CHANGE_STATUS);
const updateChangeStatusSuccess = createAction(types.CHANGE_STATUS_SUCCESS);
const getFetchPosts = createAction(types.FETCH_POSTS);
const storePost = createAction(types.STORE_POST);
const StorePostSuccess = createAction(types.STORE_POST_SUCCESS);
const editPost = createAction(types.EDIT_POST);
const showEditPost = createAction(types.SHOW_EDIT_POST);
const updatePost = createAction(types.UPDATE_POST, (payload) => payload);
const updatePostSuccess = createAction(types.UPDATE_POST_SUCCESS);
const clearData = createAction(types.CLEAR_DATA);
const deletePost = createAction(types.DELETE_POST);
const deletePostSuccess = createAction(types.DELETE_POST_SUCCESS);
const getFetchPostByCategoryId = createAction(types.FETCH_POST_HOMEPAGE);
const fetchPostByCategoryIdSuccess = createAction(types.FETCH_POST_HOMEPAGE_SUCCESS);
const fetchAchievement = createAction(types.FETCH_ACHIEVEMENT);
const fetchAchievementSuccess = createAction(types.FETCH_ACHIEVEMENT_SUCCESS);
const fetchHacktivity = createAction(types.FETCH_HACKTIVITY);
const fetchActivitiesSuccess = createAction(types.FETCH_ACTIVITIES_SUCCESS);
const storePaginateHacktivity = createAction(types.STORE_PAGINATE_HACKTIVITY);
const storePaginateAchievement = createAction(types.STORE_PAGINATE_ACHIEVEMENT);
const storePaginateActivities = createAction(types.STORE_PAGINATE_ACTIVITIES);
const fetchActivities = createAction(types.FETCH_ACTIVITIES);
const fetchHactivitySuccess = createAction(types.FETCH_HACKTIVITY_SUCCESS);
const fetchPublication = createAction(types.FETCH_PUBLICATION);
const fetchPublicationSuccess = createAction(types.FETCH_PUBLICATION_SUCCESS);
const deleteTag = createAction(types.DELETE_TAG);
const deleteTagSuccess = createAction(types.DELETE_TAG_SUCCESS);
const changePin = createAction(types.CHANGE_PIN);
const changePinSuccess = createAction(types.CHANGE_PIN_SUCCESS);
const storePostFailed = createAction(types.STORE_POST_FAILED);

export const actions = {
    getFetchPosts,
    changeStatus,
    deletePost,
    storePost,
    editPost,
    updatePost,
    clearData,
    getFetchPostByCategoryId,
    fetchAchievement,
    fetchActivities,
    fetchHacktivity,
    fetchPublication,
    deleteTag,
    changePin
};

//= =============== SAGAS ===============//
export function* sagas() {
    yield takeLatest(types.FETCH_POSTS, getPostList);
    yield takeLatest(types.CHANGE_STATUS, handleChangeStatus);
    yield takeLatest(types.STORE_POST, handleStorePost);
    yield takeLatest(types.DELETE_POST, handleDeletePost);
    yield takeLatest(types.EDIT_POST, handleEditPost);
    yield takeLatest(types.UPDATE_POST, handleUpdatePost);
    yield takeLatest(types.FETCH_POST_HOMEPAGE, fetchPostByCategoryId);
    yield takeLatest(types.FETCH_ACHIEVEMENT, fetchListAchievement);
    yield takeLatest(types.FETCH_HACKTIVITY, fetchListHacktivity);
    yield takeLatest(types.FETCH_ACTIVITIES, fetchListActivities);
    yield takeLatest(types.FETCH_PUBLICATION, fetchListPublication);
    yield takeLatest(types.DELETE_TAG, deleteTagInPost);
    yield takeLatest(types.CHANGE_PIN, changePinOfPost);
}

function* changePinOfPost({payload}) {
    try {
        const response = yield call(api.changePinApi, payload.id, payload);
        if (response.status === 200) {
            yield put(changePinSuccess(response.data));
            yield put(loadSuccess());
            message.success('Update Pin successfully !!!');
        } else {
            message.error('Something went wrong');
        }
    } catch (e) {
        handleError(e);
        yield put(loadFail());
    }
}

function* deleteTagInPost({payload}) {
    try {
        const response = yield call(api.deleteTagApi, payload);
        if (response.status === 200) {
            yield put(deleteTagSuccess(payload));
        }
    } catch (e) {
        handleError(e);
        yield put(loadFail());
    }
}

function* fetchListActivities({payload}) {
    try {
        const response = yield call(api.getListActivitiesApi, payload);
        const {data, meta} = response.data;
        const dataPaginate = {currentPage: meta.current_page, perPage: meta.per_page, total: meta.total};
        yield put(fetchActivitiesSuccess(data));
        yield put(storePaginateActivities(dataPaginate));
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}

function* fetchListPublication({payload}) {
    try {
        const response = yield call(api.getListPublication, payload);
        const {data} = response.data;
        yield put(fetchPublicationSuccess(data));
        yield put(loadSuccess());
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}

function* fetchListHacktivity({payload}) {
    try {
        const response = yield call(api.getListHacktivityApi, payload);
        const {data, meta} = response.data;
        const dataPaginate = {currentPage: meta.current_page, perPage: meta.per_page, total: meta.total};
        yield put(fetchHactivitySuccess(data));
        yield put(storePaginateHacktivity(dataPaginate));
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}

function* fetchListAchievement({payload}) {
    try {
        const response = yield call(api.getListAchievementApi, payload);
        const {data, meta} = response.data;
        const dataPaginate = {currentPage: meta.current_page, perPage: meta.per_page, total: meta.total};
        yield put(fetchAchievementSuccess(data));
        yield put(storePaginateAchievement(dataPaginate));
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}

function* fetchPostByCategoryId({payload}) {
    try {
        const response = yield call(api.getListPostByCategoryIdApi, payload);
        const {data} = response;
        if (data.listPublication) {
            yield put(loadFail());
        }
        yield put(fetchPostByCategoryIdSuccess(data));
        yield delay(500);
        yield put(loadSuccess());
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}

function* getPostList({payload}) {
    try {
        const response = yield call(api.getListPostApi, payload);
        const {data, meta} = response.data;
        const dataPaginate = {currentPage: meta.current_page, perPage: meta.per_page, total: meta.total};
        yield put(fetchPostsSuccess(data));
        yield put(storePaginate(dataPaginate));
        yield put(loadSuccess());
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}

function* handleChangeStatus({payload}) {
    try {
        const response = yield call(api.changeStatus, payload.id, payload);
        if (response.status === 200) {
            yield put(updateChangeStatusSuccess(response.data));
            yield put(loadSuccess());
            message.success('Update status successfully !!!');
        } else {
            message.error('Something went wrong');
        }
    } catch (error) {
        handleError(error);
        yield put(loadFail());
    }
}

function* handleStorePost({payload}) {
    const {history, linkToMyHome} = payload;
    try {
        const response = yield call(api.apiStore, payload);
        const {data} = response.data;
        yield put(StorePostSuccess({data}));
        yield put(loadSuccess());
        history.push(linkToMyHome);
        message.success('Created successfully!');
    } catch (e) {
        message.error('Something went wrong');
        const {errors} = e.response.data;
        yield put(storePostFailed(errors));
        yield put(loadFail());
    }
}

function* handleEditPost({payload}) {
    try {
        if (payload) {
            const response = yield call(api.editPostApi, payload);
            const {data} = response.data;
            const {tags} = response.data.data;
            const newArr = [];
            if (tags.length) {
                tags.map((item) => newArr.push({id: item.name, text: item.name}));
                data.tags = newArr;
            }
            yield put(showEditPost(data));
            yield put(loadSuccess());
        }
    } catch (e) {
        handleError(e);
    }
}

function* handleUpdatePost({payload}) {
    try {
        const {id, data, history, linkToMyHome} = payload;
        const response = yield call(api.updatePostApi, id, data);
        yield put(updatePostSuccess(response.data));
        history.push(linkToMyHome);
        message.success('Updated successfully!');
        yield put(loadSuccess());
    } catch (e) {
        message.error('Something went wrong');
        const {errors} = e.response.data;
        yield put(storePostFailed(errors));
        yield put(loadFail());
    }
}

function* handleDeletePost({payload}) {
    try {
        const response = yield call(api.deletePost, payload);
        if (response.status === 200) {
            yield put(deletePostSuccess(payload));
            message.success('Deleted successfully!!!');
        } else {
            message.error('Something went wrong');
        }
    } catch (error) {
        handleError(error);
    }
}
