import types from './types';
import {sagas, actions} from './actions';
import reducer, {selectors} from './reducer';

export default reducer;

export {
    types as resumeTypes,
    sagas as resumeSagas,
    actions as resumeActions,
    selectors as resumeSelectors
};
