const FETCH_RESUMES = 'CurriculumVitae/FETCH_RESUMES';
const FETCH_RESUMES_SUCCESS = 'CurriculumVitae/FETCH_RESUMES_SUCCESS';
const FETCH_RESUMES_FAIL = 'CurriculumVitae/FETCH_RESUMES_FAIL';
const LOAD_SUCCESS = 'CurriculumVitae/LOAD_SUCCESS';
const LOAD_FAIL = 'CurriculumVitae/LOAD_FAIL';
const STORE_PAGINATE = 'CurriculumVitae/STORE_PAGINATE';
const DELETE_RESUMES = 'CurriculumVitae/DELETE_RESUMES';
const DELETE_RESUMES_SUCCESS = 'CurriculumVitae/DELETE_RESUMES_SUCCESS';
const CHANGE_STATUS = 'CurriculumVitae/CHANGE_STATUS';

export default {
    FETCH_RESUMES,
    FETCH_RESUMES_SUCCESS,
    FETCH_RESUMES_FAIL,
    STORE_PAGINATE,
    LOAD_SUCCESS,
    LOAD_FAIL,
    DELETE_RESUMES,
    DELETE_RESUMES_SUCCESS,
    CHANGE_STATUS
};
