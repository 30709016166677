import types from './types';
import { sagas, actions } from './actions';
import reducer, { selectors } from './reducer';

export default reducer;

export {
    types as postTypes,
    sagas as postSagas,
    actions as postActions,
    selectors as postSelectors
};
