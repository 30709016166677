import React, {useEffect} from 'react';
import PropTypes from 'prop-types';
import {Layout} from 'antd';
import ReactGA from 'react-ga';
import { useHistory, withRouter } from 'react-router-dom';
import i18n from '~/i18n';
import language from '~/utils/language';

import Header from './Header';
import Footer from './Footer';
import './style.scss';

const {Content} = Layout;

ReactGA.initialize(process.env.GOOGLE_ANALYTICS);
ReactGA.pageview(window.location.pathname + window.location.search);

const LayoutHomePageComponent = ({childrenProps}) => {
    const history = useHistory();
    useEffect(() => {
        const url = window.location.search.split('=');
        if (!url[1]) {
            const tempLang = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').toUpperCase() : language.LANGUAGES.EN;
            localStorage.setItem('i18nextLng', tempLang);
            i18n.changeLanguage(tempLang);
        } else {
            const arrayLanuage = [
                language.LANGUAGES.EN,
                language.LANGUAGES.VI,
                language.LANGUAGES.JP
            ];
            if (arrayLanuage.includes(url[1])) {
                localStorage.setItem('i18nextLng', url[1].toUpperCase());
                i18n.changeLanguage(url[1].toUpperCase());
            } else {
                history.push({
                    search: `?lang=${language.LANGUAGES.EN}`
                });
                localStorage.setItem('i18nextLng', language.LANGUAGES.EN);
            }
        }

        history.listen(() => {
            ReactGA.pageview(window.location.pathname + window.location.search);
        });
    }, [history]);

    return (
        <Layout style={{minHeight: '100vh'}}>
            <Header />
            <Content>
                {childrenProps}
            </Content>
            <Footer />
        </Layout>
    );
};

LayoutHomePageComponent.propTypes = {
    childrenProps: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node
    ]).isRequired
};

export default withRouter(LayoutHomePageComponent);
