import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

import translationEN from '../public/locales/en/translation';
import translationVI from '../public/locales/vi/translation';
import translationJP from '../public/locales/jp/translation';
import homeEN from '../public/locales/en/home';
import homeVI from '../public/locales/vi/home';
import homeJP from '../public/locales/jp/home';
import aiEN from '../public/locales/en/teamai';
import aiVI from '../public/locales/vi/teamai';
import aiJP from '../public/locales/jp/teamai';

// the translations
const resources = {
    EN: {
        translation: translationEN,
        home: homeEN,
        ai: aiEN
    },
    VI: {
        translation: translationVI,
        home: homeVI,
        ai: aiVI
    },
    JP: {
        translation: translationJP,
        home: homeJP,
        ai: aiJP
    }
};

i18n
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
        resources,
        lng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').toUpperCase() : 'EN',
        fallbackLng: localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').toUpperCase() : 'EN', // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
            escapeValue: false // react already safes from xss
        }
    });

export default i18n;
