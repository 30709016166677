import axios from '~/utils/axios';

// Route Admin
const getListTagsApi = (param) => axios.get('/api/admin/tags', {params: param});

const getListTagPublication = (param) => axios.get('api/homepage/tags', {params: param});
export default {
    getListTagsApi,
    getListTagPublication
};
