import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Link, NavLink, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { languageActions } from '~/state/modules/language';
import Logo from '~/assets/images/logo.png';
import LangENG from '~/assets/images/eng.png';
import LangJPN from '~/assets/images/jpn.png';
import LangVIE from '~/assets/images/vie.png';
import language from '~/utils/language';
import './style.scss';

const Header = ({getUrlLanguage}) => {
    const {t, i18n} = useTranslation();
    const history = useHistory();

    const [subMenu, setSubmenu] = useState(false);

    function handleShowMenu() {
        setSubmenu(!subMenu);
    }

    function handleCloseMenu() {
        setSubmenu(false);
    }

    function toTop() {
        window.scrollTo(0, 0);
    }

    const onChangeLanguage = (code) => {
        i18n.changeLanguage(code);
        switch (code) {
        case language.LANGUAGES.EN:
            getUrlLanguage({lang: language.LANGUAGES.EN});
            return history.push({
                search: `?lang=${language.LANGUAGES.EN}`
            });
        case language.LANGUAGES.VI:
            getUrlLanguage({lang: language.LANGUAGES.VI});
            return history.push({
                search: `?lang=${language.LANGUAGES.VI}`
            });
        case language.LANGUAGES.JP:
            getUrlLanguage({lang: language.LANGUAGES.JP});
            return history.push({
                search: `?lang=${language.LANGUAGES.JP}`
            });
        default:
            getUrlLanguage({lang: language.LANGUAGES.EN});
            return history.push({
                search: `?lang=${language.LANGUAGES.EN}`
            });
        }
    };

    const menuItems = [{
        title: t('AI Research'),
        link: '/team-ai'
    }, {
        title: t('Blockchain Research'),
        link: '/blockchain'
    }, {
        title: t('Cyber Security Research'),
        link: '/cyber-security'
    }, {
        title: t('Product Development'),
        link: '/product'
    }, {
        title: t('Publication'),
        link: '/publication'
    }];

    const languageItems = [{
        name: 'ENG',
        code: language.LANGUAGES.EN,
        icon: LangENG
    }, {
        name: 'VIE',
        code: language.LANGUAGES.VI,
        icon: LangVIE
    }, {
        name: 'JPN',
        code: language.LANGUAGES.JP,
        icon: LangJPN
    }];

    return (
        <header className='header-page border-b border-gray-400 px-4 fixed top-0 left-0 z-50 w-full bg-white'>
            <div className='container m-auto relative'>
                <Link to='/' className='logo inline-block text-gray-800 hover:text-gray-800' onClick={() => { handleCloseMenu(); toTop(); }}>
                    <span className='flex items-center pt-4 mb-3 lg:mb-0'>
                        <img src={Logo} alt='Logo' />
                        <span className='font-bold text-2xl ml-2 inline-block'>R&D Lab</span>
                    </span>
                </Link>
                <nav className='flex items-center justify-between flex-wrap text-gray-800'>
                    <div className='button-menu block lg:hidden absolute right-0'>
                        <div className='flex items-center px-3 py-2 border rounded cursor-pointer' type='button' onClick={handleShowMenu}>
                            <svg className='fill-current h-3 w-3' viewBox='0 0 20 20' xmlns='http://www.w3.org/2000/svg'>
                                <title>Menu</title>
                                <path d='M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z' />
                            </svg>
                        </div>
                    </div>

                    <div className={`menu w-full flex-grow lg:flex lg:items-center lg:w-auto ${subMenu ? 'block' : 'hidden'}`}>
                        <div className='text-sm lg:flex-grow mb-5 lg:mb-0 lg:order-last flex justify-between sm:justify-end lg:w-auto w-full'>
                            {languageItems.map((item) => (
                                <div
                                    key={item.code}
                                    className={`mt-4 py-2 lg:mt-0 sm:pl-4 lg:py-5 flex cursor-pointer ${item.name === 'JPN' ? 'hidden' : ''}`}
                                    onClick={() => { onChangeLanguage(item.code); handleCloseMenu(); }}
                                >
                                    <img src={item.icon} alt='Language ENG' width='32' height='24' className='border shadow' />
                                    <span className='block ml-2'>{item.name}</span>
                                </div>
                            ))}
                        </div>
                        <div className='text-sm lg:flex-grow mb-5 lg:mb-0'>
                            {menuItems.map((item) => (
                                <NavLink
                                    key={item.link}
                                    to={item.link}
                                    className='block mt-4 py-2 lg:inline-block lg:mt-0 lg:mr-4 lg:py-5'
                                    activeClassName='active border-b-2 border-red-600'
                                    onClick={() => { handleCloseMenu(); toTop(); }}
                                >
                                    {item.title}
                                </NavLink>
                            ))}
                        </div>
                    </div>
                </nav>
            </div>
        </header>
    );
};

Header.propTypes = {
    getUrlLanguage: PropTypes.func.isRequired
};

const mapDispatchToProps = {
    getUrlLanguage: languageActions.getUrlLanguage
};

export default connect(null, mapDispatchToProps)(Header);
