export default {
    TEAMS: {
        PRODUCT: 'Open Source Research',
        AI: 'AI Research',
        BLOCKCHAIN: 'Blockchain Research',
        CYBER: 'Cyber Security Research'
    },
    TEAM_IDS: {
        PRODUCT: 'open_source_research',
        AI: 'ai_research',
        BLOCKCHAIN: 'blockchain_research',
        CYBER: 'cyber_security_research'
    }
};
