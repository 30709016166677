import React, {Component} from 'react';
import {Switch, Route} from 'react-router-dom';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Immutable from 'immutable';
import routes from '~/routes';
import sentryCapture from '~/utils/Sentry/Capture';
import Layout from './components/layouts';
import {getAuthUser} from '~/state/modules/auth';
import {languageActions} from '~/state/modules/language';
import language from '~/utils/language';

class App extends Component {
    static getDerivedStateFromError() {
        return {hasError: true};
    }

    componentDidMount() {
        const {getUrlLanguage} = this.props;
        const url = window.location.search.split('=');
        if (!url[1]) {
            const tempLang = localStorage.getItem('i18nextLng') ? localStorage.getItem('i18nextLng').toUpperCase() : language.LANGUAGES.EN;
            getUrlLanguage({lang: tempLang});
        } else {
            getUrlLanguage({ lang: url[1].toUpperCase() });
        }
    }

    componentDidCatch(error, errorInfo) {
        sentryCapture(error, errorInfo);
    }

    renderRoute = (routeList) => (
        <Switch>
            {
                routeList.map((route) => (
                    <Route key={route.name} {...route} />
                ))
            }
        </Switch>
    );

    render() {
        const {authUser} = this.props;
        return (
            <div className='app'>
                <Layout>
                    {
                        (authUser)
                            ? this.renderRoute([...routes.routeHomePageAdmins, ...routes.routeHomePages])
                            : this.renderRoute(routes.routeHomePages)
                    }

                </Layout>
            </div>
        );
    }
}

App.defaultProps = {
    authUser: {}
};

App.propTypes = {
    authUser: PropTypes.instanceOf(Immutable.Map),
    getUrlLanguage: PropTypes.func.isRequired
};

const mapStateToProps = (state) => ({
    authUser: getAuthUser(state)
});

const mapDispatchToProps = {
    getUrlLanguage: languageActions.getUrlLanguage
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
