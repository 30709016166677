import {all, fork} from 'redux-saga/effects';

import {exampleSagas} from './modules/example';
import {postSagas} from './modules/post';
import {resumeSagas} from './modules/CurriculumVitae';
import {languageSagas} from './modules/language';
import {tagSagas} from './modules/tag';

export default function* rootSaga() {
    yield all([
        fork(exampleSagas),
        fork(postSagas),
        fork(resumeSagas),
        fork(languageSagas),
        fork(tagSagas)
    ]);
}
