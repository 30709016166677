import Loadable from '~/utils/Loadable/';

const routeHomePages = [
    {
        exact: true,
        path: '/',
        component: Loadable(() => import('~/views/pages/Home')),
        name: 'home'
    },
    {
        exact: true,
        path: '/home',
        component: Loadable(() => import('~/views/pages/Home')),
        name: 'home'
    },
    {
        exact: true,
        path: '/blockchain',
        component: Loadable(() => import('~/views/pages/BlockChain')),
        name: 'blockchain'
    },
    {
        path: '/team-ai',
        component: Loadable(() => import('~/views/pages/TeamAI')),
        name: 'team-ai'
    },
    {
        path: '/product',
        component: Loadable(() => import(/* webpackChunkName: "js/teamai" */ '~/views/pages/Product')),
        name: 'product'
    },
    {
        path: '/publication',
        component: Loadable(() => import('~/views/pages/Publication')),
        name: 'publication'
    },
    {
        path: '/careers',
        component: Loadable(() => import('~/views/pages/Careers')),
        name: 'careers'
    },
    {
        path: '/cyber-security',
        component: Loadable(() => import(/* webpackChunkName: "js/teamai" */ '~/views/pages/CyberSecurity')),
        name: 'cyber-security'
    },
    {
        exact: true,
        path: '*',
        component: Loadable(() => import('~/views/pages/404')),
        name: 'error-not-found'
    }
];

export default routeHomePages;
