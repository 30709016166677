import React from 'react';
import {Layout, Dropdown, Menu, Avatar} from 'antd';
import {DownOutlined, UserOutlined} from '@ant-design/icons';
import './style.scss';

const {Header} = Layout;

const menu = (
    <Menu>
        <Menu.Item key='logedout'>
            <a href='/logedout'>
                <span>Logout</span>
            </a>
        </Menu.Item>
    </Menu>
);
const HeaderAdmin = () => (
    <Header className='site-layout-background' style={{padding: 0}}>
        <div className='header-admin'>
            <Dropdown overlay={menu}>
                <a className='ant-dropdown-link' onClick={(e) => e.preventDefault()}>
                    <span>ADMIN</span>
                    <DownOutlined />
                </a>
            </Dropdown>
            <div className='avatar-header'>
                <Avatar size='large' icon={<UserOutlined />} />
            </div>
        </div>
    </Header>
);

export default HeaderAdmin;
