const FETCH_TAG = 'tag/FETCH_TAG';
const FETCH_TAG_SUCCESS = 'tag/FETCH_TAG_SUCCESS';
const FETCH_TAG_FAIL = 'tag/FETCH_TAG_FAIL';
const LOAD_SUCCESS = 'tag/LOAD_SUCCESS';
const LOAD_FAIL = 'tag/LOAD_FAIL';
const FETCH_TAG_PUBLICATION = 'tag/FETCH_TAG_PUBLICATION';
const FETCH_TAG_PUBLICATION_SUCCESS = 'tag/FETCH_TAG_PUBLICATION_SUCCESS';

export default {
    FETCH_TAG,
    FETCH_TAG_SUCCESS,
    FETCH_TAG_FAIL,
    LOAD_SUCCESS,
    LOAD_FAIL,
    FETCH_TAG_PUBLICATION,
    FETCH_TAG_PUBLICATION_SUCCESS
};
