const FETCH_POSTS = 'post/FETCH_POSTS';
const FETCH_POSTS_SUCCESS = 'post/FETCH_POSTS_SUCCESS';
const FETCH_POSTS_FAIL = 'post/FETCH_POSTS_FAIL';
const LOAD_SUCCESS = 'post/LOAD_SUCCESS';
const LOAD_FAIL = 'post/LOAD_FAIL';
const STORE_PAGINATE = 'post/STORE_PAGINATE';
const CHANGE_STATUS = 'post/CHANGE_STATUS';
const CHANGE_STATUS_SUCCESS = 'post/CHANGE_STATUS_SUCCESS';
const STORE_POST = 'post/STORE_POST';
const STORE_POST_SUCCESS = 'post/STORE_POST_SUCCESS';
const STORE_POST_FAILED = 'post/STORE_POST_FAILED';
const EDIT_POST = 'post/EDIT_POST';
const SHOW_EDIT_POST = 'post/SHOW_EDIT_POST';
const UPDATE_POST = 'post/UPDATE_POST';
const UPDATE_POST_SUCCESS = 'post/UPDATE_POST_SUCCESS';
const CLEAR_DATA = 'post/CLEAR_DATA';
const DELETE_POST = 'post/DELETE_POST';
const DELETE_POST_SUCCESS = 'post/DELETE_POST_SUCCESS';
const FETCH_POST_HOMEPAGE = 'post/FETCH_POST_HOMEPAGE';
const FETCH_POST_HOMEPAGE_SUCCESS = 'post/FETCH_POST_HOMEPAGE_SUCCESS';
const FETCH_POST_HOMEPAGE_FAIL = 'post/FETCH_POST_HOMEPAGE_FAIL';
const FETCH_ACHIEVEMENT = 'post/FETCH_ACHIEVEMENT';
const FETCH_ACHIEVEMENT_SUCCESS = 'post/FETCH_ACHIEVEMENT_SUCCESS';
const FETCH_HACKTIVITY = 'post/FETCH_HACKTIVITY';
const FETCH_HACKTIVITY_SUCCESS = 'post/FETCH_HACKTIVITY_SUCCESS';
const STORE_PAGINATE_HACKTIVITY = 'post/STORE_PAGINATE_HACKTIVITY';
const STORE_PAGINATE_ACHIEVEMENT = 'post/STORE_PAGINATE_ACHIEVEMENT';
const FETCH_ACTIVITIES = 'post/FETCH_ACTIVITIES';
const FETCH_ACTIVITIES_SUCCESS = 'post/FETCH_ACTIVITIES_SUCCESS';
const STORE_PAGINATE_ACTIVITIES = 'post/STORE_PAGINATE_ACTIVITIES';
const FETCH_PUBLICATION = 'post/FETCH_PUBLICATION';
const FETCH_PUBLICATION_SUCCESS = 'post/FETCH_PUBLICATION_SUCCESS';
const DELETE_TAG = 'post/DELETE_TAG';
const DELETE_TAG_SUCCESS = 'post/DELETE_TAG_SUCCESS';
const CHANGE_PIN = 'post/CHANGE_PIN';
const CHANGE_PIN_SUCCESS = 'post/CHANGE_PIN_SUCCESS';

export default {
    FETCH_POSTS_FAIL,
    FETCH_POSTS,
    FETCH_POSTS_SUCCESS,
    STORE_PAGINATE,
    LOAD_SUCCESS,
    LOAD_FAIL,
    CHANGE_STATUS,
    CHANGE_STATUS_SUCCESS,
    STORE_POST,
    STORE_POST_SUCCESS,
    STORE_POST_FAILED,
    EDIT_POST,
    SHOW_EDIT_POST,
    UPDATE_POST,
    UPDATE_POST_SUCCESS,
    CLEAR_DATA,
    DELETE_POST,
    DELETE_POST_SUCCESS,
    FETCH_POST_HOMEPAGE,
    FETCH_POST_HOMEPAGE_SUCCESS,
    FETCH_POST_HOMEPAGE_FAIL,
    FETCH_ACHIEVEMENT,
    FETCH_ACHIEVEMENT_SUCCESS,
    FETCH_HACKTIVITY,
    FETCH_HACKTIVITY_SUCCESS,
    STORE_PAGINATE_HACKTIVITY,
    STORE_PAGINATE_ACHIEVEMENT,
    FETCH_PUBLICATION,
    FETCH_PUBLICATION_SUCCESS,
    FETCH_ACTIVITIES,
    FETCH_ACTIVITIES_SUCCESS,
    STORE_PAGINATE_ACTIVITIES,
    DELETE_TAG,
    DELETE_TAG_SUCCESS,
    CHANGE_PIN,
    CHANGE_PIN_SUCCESS
};
